"use strict";

export default function main() {

    const windowHeight = $(window).height();
    $(function() {
        hamburger();
    });

    function hamburger() {

        $(".nav_trigger").on('click', function() {
            $("#nav").addClass("show");
            $("#navOverlay").addClass('show');
        });
        $("#navClose").on('click', function() {
            $("#nav").removeClass("show");
            $("#navOverlay").removeClass('show');
        })
        $("#navOverlay").on('click', function() {
            $("#nav").removeClass("show");
            $("#navOverlay").removeClass('show');
        })
        $("#nav").find('.__contents').find("a").on('click', function() {
            $("#nav").removeClass("show");
            $("#navOverlay").removeClass('show');
        })
    }
    //topへスムースscroll
    $(function() {
        const pageTop = $('#toTop');
        $(window).on("scroll", function() {
            if ($(this).scrollTop() > 300) {
                pageTop.fadeIn();
            } else {
                pageTop.fadeOut();
            }
        });
        pageTop.on("click", function() {
            $('body, html').animate({
                scrollTop: 0
            }, 500);
            return false;
        });
    });

    //scroll始まったらheaderを薄くする
    // $(function() {
    //     $(window).on("scroll", function() {
    //         headerScroll()
    //     });

    //     function headerScroll() {
    //         if ($(window).scrollTop() > 100) {
    //             $('#header').addClass('scroll')
    //         } else {
    //             $('#header').removeClass('scroll')
    //         }
    //     }
    // });
    let lastScrollTop = 0;
    const navbar = document.querySelector('.navbar');

    window.addEventListener('scroll', function () {
        let currentScroll = window.pageYOffset || document.documentElement.scrollTop;

        if (currentScroll > lastScrollTop && currentScroll > 0) {
            // 下にスクロールした場合、.hiddenクラスを追加
            $('#header').addClass('scroll')
        } else {
            // 上にスクロールした場合、.hiddenクラスを削除
            $('#header').removeClass('scroll')
        }

        lastScrollTop = currentScroll;
    });
    
    //faqページ　scrollでnavにクラスをつける
    $(function() {
        const pageFaq = $(".p_faq");
        if (pageFaq.length > 0) {
            $(window).on("scroll", function() {
                navAddClass()
            });

            function navAddClass() {
                if ($(window).scrollTop() > 350) {
                    pageFaq.find(".p_c_nav").addClass("addBoxShadow")
                } else {
                    pageFaq.find(".p_c_nav").removeClass("addBoxShadow")
                }
            }
        }

    });
    // -------------------------------------------------animation
    function animation() {
        $(function() {

            $(".animation").each(function() {
                let $animation = $(this),
                    animationOffsetTop = $animation.offset().top;

                const adjust = -150;

                if ($(window).scrollTop() > animationOffsetTop - windowHeight - adjust) {

                    $($animation).addClass('fuwa');
                }
            });
        });
    }
    $(window).on('scroll', function() {
        animation();
    });
    animation();
    let lastInnerWidth = $(window).innerWidth();
    $(window).on('resize', function() {
        // 現在と前回の横幅が違う場合だけ実行
        if (lastInnerWidth != $(window).innerWidth()) {
            // 横幅を記録しておく
            lastInnerWidth = window.innerWidth;
            animation();
        }
    });
    $(window).on('myEvent', function() {
        // 'myEvent' が発生した時に実行したい処理
        animation();
    });
    $(function() {

        //URLのハッシュ値を取得
        var urlHash = location.hash;
        //ハッシュ値があればページ内スクロール
        if (urlHash) {
            //スクロールを0に戻す
            $('body,html').stop().scrollTop(0);
            setTimeout(function() {
                //ロード時の処理を待ち、時間差でスクロール実行
                scrollToAnker(urlHash);
            }, 10);
        }

        //通常のクリック時
        $('a[href^="#"]').click(function() {
            //ページ内リンク先を取得
            var href = $(this).attr("href");
            //リンク先が#か空だったらhtmlに
            var hash = href == "#" || href == "" ? 'html' : href;
            //スクロール実行
            scrollToAnker(hash);
            //リンク無効化
            return false;
        });
        //faqページのスマホ時のselect
        $('#navSelect').on('change', function(e) {
            let val = $(this).val();
            val = '#' + val;
            scrollToAnker(val);
        })
        // 関数：スムーススクロール
        // 指定したアンカー(#ID)へアニメーションでスクロール
        function scrollToAnker(hash) {
            if (hash.startsWith(`#makeshop`)) {
                return;
            }
            if (hash.startsWith(`#CategoryArea`)) {
                return;
            }
            var target = $(hash);
            var position = target.offset().top;
            const speed = 500;

            if ($(window).width() > 767) {
                var adjust = 175;
            } else {
                var adjust = 98;
            }
            var position_adjust = position - adjust;
            $("html, body").animate({
                scrollTop: position_adjust
            }, speed, "swing");
        }

    });
    //.m-link--empty classをもってるaタグはいったんリンクの挙動を止める
    $(function() {
        $(".m-link--empty").on('click', function() {
            return false;
        });
    });
    //sideボタンの表示非表示
    $(function() {
        showSideFix();
        $(window).on('scroll', function() {
            showSideFix();
        });

        function showSideFix() {
            let sideFix = $(".c_fixed_side");
            if ($(window).scrollTop() > 100) {
                sideFix.addClass('show');
            } else {
                sideFix.removeClass('show');
            }
        }
    })
    // メニューを開く関数
    const slideDown = (el) => {
        el.style.height = 'auto'; //いったんautoに
        let h = el.offsetHeight; //autoにした要素から高さを取得
        el.style.height = h + 'px';
        el.animate([ //高さ0から取得した高さまでのアニメーション
            {
                height: 0
            },
            {
                height: h + 'px'
            }
        ], {
            duration: 300, //アニメーションの時間（ms）
        });
    };

    // メニューを閉じる関数
    const slideUp = (el) => {
        el.style.height = 0;
    };

    let activeIndex = null; //開いているアコーディオンのindex

    //アコーディオンコンテナ全てで実行
    const accordions = document.querySelectorAll('ul.include-accordion');
    accordions.forEach((accordion) => {

        //アコーディオンボタン全てで実行
        const accordionBtns = accordion.querySelectorAll('.__accordion_btn');
        accordionBtns.forEach((accordionBtn, index) => {
            accordionBtn.addEventListener('click', (e) => {
                activeIndex = index; //クリックされたボタンを把握
                e.target.parentNode.classList.toggle('active'); //ボタンの親要素（=ul>li)にクラスを付与／削除
                const content = accordionBtn.nextElementSibling; //ボタンの次の要素（=ul>ul）
                if (e.target.parentNode.classList.contains('active')) {
                    slideDown(content); //クラス名がactive（＝閉じていた）なら上記で定義した開く関数を実行
                } else {
                    slideUp(content); //クラス名にactiveがない（＝開いていた）なら上記で定義した閉じる関数を実行
                }
                accordionBtns.forEach((accordionBtn, index) => {
                    if (activeIndex !== index) {
                        accordionBtn.parentNode.classList.remove('active');
                        const openedContent = accordionBtn.nextElementSibling;
                        slideUp(openedContent); //現在開いている他のメニューを閉じる
                    }
                });
                //スクロール制御のために上位階層ulのクラス名を変える
                let container = accordion.closest('.scroll-control'); //sroll-controlnのクラス名である親要素を取得
                if (accordionBtn.parentNode.classList.contains('active') == false && container !== null) {
                    container.classList.remove('active')
                } else if (container !== null) {
                    container.classList.add('active')
                }
            });
        });
    });

    // お客様の声　アコーディオンボタン
    //    const btn = document.querySelector('.btn_more button');
    //        btn.addEventListener('click', () => {
    //            const more = document.querySelector('.more');
    //            more.classList.toggle('appear');
    //
    //            if (btn.textContent == "もっと見る") {
    //              btn.textContent = "閉じる";
    //            } else {
    //              btn.textContent = "もっと見る";
    //            }
    //      });


    // スクロールTOPボタン
    const returnTop = document.getElementById('page_top');

    window.addEventListener('scroll', () => {
        let scrollY = window.scrollY;
        if (scrollY >= 180) {
            returnTop.classList.add('show');
        } else {
            returnTop.classList.remove('show');
        }
    });



}